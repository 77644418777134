// React
import React, { useEffect } from "react";
// Third party
import { useFormik } from "formik";
import * as Yup from "yup";
// OpenAPI
import { ContactDomainApi, ContactRequest } from "../../../openapi";
// Components
import { apiConfig } from "../../../components/ConfigurationApi/Configuration";
import DeleteContactModal from "../../../components/DeleteModal/DeleteContactModal/DeleteContactModal";
import EditableFieldMna from "../../../components/EditableFields/EditableField";
// Images
import DeleteIcon from "../../../assets/svg/delete.svg";
import SubmitIcon from "../../../assets/svg/submit-icon.svg";
import CancelIcon from "../../../assets/svg/cancel-icon.svg";
import AddContactIcon from "../../../assets/svg/addContactIcon.svg";

interface ContactsProps {
  data: any;
  fetchMnaData: () => void;
  setData: React.Dispatch<React.SetStateAction<any>>;
  setFetchRequired: React.Dispatch<React.SetStateAction<boolean>>;
  isContactDeleteModalOpen: boolean;
  setIsContactDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteContactId: React.Dispatch<React.SetStateAction<string>>;
  onDeleteContact: () => void;
  isContactAdd: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsContactAdd: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Contacts({
  data,
  fetchMnaData,
  setData,
  setFetchRequired,
  isContactDeleteModalOpen,
  setIsContactDeleteModalOpen,
  setDeleteContactId,
  onDeleteContact,
  isContactAdd,
  setLoading,
  setIsContactAdd,
}: ContactsProps) {
  const handleFetchMnaData = async () => {
    await fetchMnaData();
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phone is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const addContactRequestBody: ContactRequest = {
        name: values?.name,
        phone: values?.phone,
        email: values?.email,
        domain: {
          typeId: 1,
          entityId: String(data?.id),
        },
      };

      try {
        setLoading(true);
        const api = new ContactDomainApi(apiConfig());
        await api.createContact(addContactRequestBody);
        setIsContactAdd(false);
        formik.resetForm();
        // Trigger fetch to get updated data after adding contact
        setFetchRequired((prev) => !prev);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    handleFetchMnaData();
  }, [setFetchRequired]); // Fetch data when fetchRequired changes

  return (
    <div className="w-full">
      <div className="table-container p-4 relative min-h-[300px]">
        <table className="table-auto w-full rounded-bl-lg rounded-br-lg bg-table-light dark:bg-table-dark">
          <caption className="text-left text-xl font-semibold pb-2 bg-table-light dark:bg-table-dark text-[#3B97FF]">
            Contacts
          </caption>
          <thead className="">
            <tr>
              <th className="text-left  text-[#000000] font-normal">
                Name
              </th>
              <th className="text-left  text-[#000000] font-normal">
                Phone
              </th>
              <th className="text-left text-[#000000] font-normal">
                Email
              </th>
              <th className="text-left text-[#000000] font-normal"></th>
            </tr>
          </thead>
          <tbody className="">
            {data?.contacts?.length > 0 ? (
              data?.contacts?.map((details: any) => (
                <tr key={details.id}>
                  <td className="w-[30.5%] h-full">
                    <EditableFieldMna
                      fieldtype="text"
                      fieldName="name"
                      data={details?.name}
                      fullData={data}
                      dateIndex={""}
                      setFetchMnaDetails={() =>
                        setFetchRequired((prev) => !prev)
                      }
                      setLocalData={setData}
                      fetchMnaData={handleFetchMnaData}
                      isContactEdit={true}
                      contactData={details}
                    />
                  </td>
                  <td className="w-[30.5%] h-full">
                    <EditableFieldMna
                      fieldtype="text"
                      fieldName="phone"
                      data={details?.phone}
                      fullData={data}
                      dateIndex={""}
                      setFetchMnaDetails={() =>
                        setFetchRequired((prev) => !prev)
                      }
                      setLocalData={setData}
                      fetchMnaData={handleFetchMnaData}
                      isContactEdit={true}
                      contactData={details}
                    />
                  </td>
                  <td className="w-[30.5%] h-full">
                    <EditableFieldMna
                      fieldtype="text"
                      fieldName="email"
                      data={details?.email}
                      fullData={data}
                      dateIndex={""}
                      setFetchMnaDetails={() =>
                        setFetchRequired((prev) => !prev)
                      }
                      setLocalData={setData}
                      fetchMnaData={handleFetchMnaData}
                      isContactEdit={true}
                      contactData={details}
                    />
                  </td>
                  <td className="flex justify-center h-full">
                    <img
                      src={DeleteIcon}
                      alt="delete"
                      className="h-full w-[30px] object-contain cursor-pointer"
                      onClick={() => {
                        setIsContactDeleteModalOpen(true);
                        setDeleteContactId(details?.id);
                      }}
                    />
                    <DeleteContactModal
                      isDeleteModalOpen={isContactDeleteModalOpen}
                      setIsDeleteModalOpen={setIsContactDeleteModalOpen}
                      onDeleteItem={() => {
                        onDeleteContact();
                        setFetchRequired((prev) => !prev); // Trigger data fetch after deleting contact
                      }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td
                  colSpan={4}
                  className="text-primary font-bold text-base p-4"
                >
                  No data found
                </td>
              </tr>
            )}
            {isContactAdd && (
              <tr className="space-x-4">
                <td className="w-[30.5%] h-full pr-1">
                  <input
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="border p-2 w-full"
                    placeholder="Enter name"
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="text-danger text-sm">
                      {formik.errors.name}
                    </div>
                  )}
                </td>
                <td className="w-[30.5%] h-full pr-1 pl-1">
                  <input
                    type="text"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="border p-2 w-full"
                    placeholder="Enter phone"
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className="text-danger text-sm">
                      {formik.errors.phone}
                    </div>
                  )}
                </td>

                <td className="w-[30.5%] h-full pr-1 pl-1">
                  <input
                    type="text"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="border p-2 w-full"
                    placeholder="Enter email"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="text-danger text-sm">
                      {formik.errors.email}
                    </div>
                  )}
                </td>

                <td className="flex justify-center h-full pt-2">
                  <img
                    src={SubmitIcon}
                    alt="Submit Icon"
                    className="h-full w-[30px] object-contain cursor-pointer"
                    onClick={() => formik.handleSubmit()}
                  />
                  <img
                    src={CancelIcon}
                    alt="Cancel Icon"
                    className="h-full w-[30px] object-contain cursor-pointer"
                    onClick={() => setIsContactAdd(false)}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="text-right p-[15px] flex justify-end">
          <img
            src={AddContactIcon}
            alt="Add Contact"
            className="cursor-pointer text-end mr-3"
            onClick={() => {
              setIsContactAdd(true);
              formik.resetForm();
            }}
          />
        </div>
      </div>
    </div>
  );
}
