import { expiredTokenValidation } from "../../../api/expiredTokenValidation";
import { apiConfig } from "../../../components/ConfigurationApi/Configuration";
import { getApiData } from "../../../controller/getApiDataConfiguration";
import { ConditionDomainApi } from "../../../openapi";

export const FetchConditionStatusDomainApi = async (pageSize: number,
  pageIndex: number,
  sortBy: any) => {
  try {
    const api = new getApiData();
    return await api.getConditionsDomainApi(pageSize, pageIndex, sortBy);
  } catch (error) {
    expiredTokenValidation(error);
    throw error;
  }
}

export const deleteConditionById = async (id: any) => {
  try {
    const api = new ConditionDomainApi(apiConfig());
    await api._delete(id);
  } catch (error) {
    expiredTokenValidation(error);
    throw error;
  }
}

export const AddConditionsDomainApi = async (newComdition: any) => {
  try {
    const api = new ConditionDomainApi(apiConfig());
    await api.createCondition(newComdition)
  } catch (error) {
    expiredTokenValidation(error);
    throw error;
  }
}

export const UpdateconditionsDomainApi = async (id: any, body: any) => {
  try {
    const api = new ConditionDomainApi(apiConfig());
    await api.updateCondition(id, body);
  } catch (error) {
    expiredTokenValidation(error);
    throw error;
  }
}