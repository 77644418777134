import * as Yup from "yup";

export const validationSchema = Yup.object({
  categoryId: Yup.string()
    .required("Category Id is required")
    .notOneOf([""], "Category must be selected"),
  dateAnnounced: Yup.string()
    .required("Date Announced is required")
    .matches(/^\d{2}\/\d{2}\/\d{4}$/, "Date must be in MM/DD/YYYY format"),
  nameTarget: Yup.string()
    .max(50, "Target name must be at most 50 characters")
    .required("Target name is required"),
  nameAcquire: Yup.string()
    .max(50, "Acquirer name must be at most 50 characters")
    .required("Acquirer name is required")
});
