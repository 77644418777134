// React
import React from 'react';
// Third party
import {Link} from "react-router-dom";
import moment from "moment/moment";
// Types
import {Note} from "../../../interfaces/pages/variedInterfaces";

interface FirstListGroupProps {
    data: any
}

const FirstListGroup: React.FC<FirstListGroupProps> = ({data}) => {
    return (
        <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="table-container">
                    <table className="min-w-full dark:bg-table-dark bg-table-light">
                        <caption
                            className="text-left heading-bold bg-primary-default dark:bg-table-dark text-[#FFF] py-3 px-[29px]">
                            Notes - Recently Modified
                        </caption>
                        <thead className="bg-table-sub-light dark:bg-black dark:text-white">
                        <tr>
                            <th
                                scope="col"
                                className="px-[29px] py-3 text-start text-primary font-semibold text-lg"
                            >
                                Title
                            </th>
                            <th
                                scope="col"
                                className="px-[29px] py-3 text-start text-primary font-semibold text-lg"
                            >
                                Date Modified
                            </th>
                            <th
                                scope="col"
                                className="px-[29px] py-3 text-start text-primary font-semibold text-lg"
                            >
                                Source Type
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-[#DCDBEE] align-top">
                        {data.notes?.map((note: Note, index: number) => (
                            <tr key={index}>
                                <td className="px-[30px] py-5 text-regular-bold break-words">
                                    <Link
                                        className="text-secondary"
                                        to={`/notes/${note?.id}`}
                                    >
                                        {note.title}
                                    </Link>
                                </td>
                                <td className="px-[30px] py-5 text-regular">
                                    {moment(note.dateModified).format(
                                        "MM/DD/YYYY hh:mm A"
                                    )}
                                </td>
                                <td
                                    scope="row"
                                    className="px-[30px] py-5 text-regular"
                                >
                                    {note?.sourceType}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default FirstListGroup;
