// React
import React, { useCallback, useEffect, useState } from "react";
// Third party
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
// Types
import { HandleMenuProps, Sort } from "../../interfaces/pages/variedInterfaces";
import { IConditions } from "../../types";
// Images
import close from "./../../assets/svg/close.svg";
// Hooks
import useFileDownload from "../../hooks/useFileDownload";
// Services
import {
  fetchSectorList,
  fetchMnaData,
  fetchFeatures,
  onDeleteContact,
} from "./services/mnaService";
// Components
import WhatMattersTab from "./components/WhatMattersTab";
import CompanyDescriptionTab from "./components/CompanyDescriptionTab";
import { Columns } from "../../components/Columns/Columns";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import CompanyInformation from "./components/CompanyInformation";
import Contacts from "./components/Contacts";
import Dates from "./components/Dates";
import MnaBasics from "./components/MnaBasics";
import TopFiveHolders from "./components/TopFiveHolders";
import MnaLinks from "./components/MnaLinks";
import ConditionsMnaTable from "../../components/Tables/ConditionsMNATable";
import { getFileSize } from "../../utils";
import Table from "../../components/Tables/RecentEvent";
import Loader from "../../components/Loader";
// OpenAPI
import { getApiData } from "../../controller/getApiDataConfiguration";
import { handleFormError } from "../../utils/errorHandling";
import { useAppDispatch, useAppSelector } from "../../store/storeHooks";
import {
  fetchMnaDetails,
  setMnaDetailsAction,
} from "../../features/mnaDetailsSlice";
import { RootState } from "../../store/store";

const MnaDetails: React.FC<HandleMenuProps> = ({ isMenuOpen }) => {
  const { handleFileDownload } = useFileDownload();

  const navigate = useNavigate();
  const { id } = useParams();
  const safeId = id!;
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [content, setContent] = useState<string | undefined>();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [sectorList, setSectorList] = useState<any>([]);
  const [fetchRequired, setFetchRequired] = useState<boolean>(false);
  const [conditionsData, setConditionsData] = useState<IConditions[]>([]);
  const [dateData, setDateData] = useState<IConditions[]>([]);
  const [isContactAdd, setIsContactAdd] = useState<boolean>(false);
  const [deleteContactId, setDeleteContactId] = useState<string>("");
  const [isContactDeleteModalOpen, setIsContactDeleteModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const getSectorList = async () => {
      const sectors = await fetchSectorList(id!);
      setSectorList(sectors);
    };
    getSectorList();
  }, [id]);

  useEffect(() => {
    const getMnaData = async () => {
      setLoading(true);
      try {
        const mnaData = await fetchMnaData(id!, fetchFeatures);
        setData(mnaData);
        setConditionsData(mnaData.conditions);

        // sort by dateEvent for outDates , BrokerClosingDates , financingDates
        const sortedMnaData = {
          ...mnaData,
          outDates: [...(mnaData.outDates || [])].sort(
            (a, b) =>
              new Date(a.dateEvent).getTime() - new Date(b.dateEvent).getTime()
          ),
          brokerClosingDates: [...(mnaData.brokerClosingDates || [])].sort(
            (a, b) =>
              new Date(a.dateEvent).getTime() - new Date(b.dateEvent).getTime()
          ),
          financingDates: [...(mnaData.financingDates || [])].sort(
            (a, b) =>
              new Date(a.dateEvent).getTime() - new Date(b.dateEvent).getTime()
          ),
        };

        setData(sortedMnaData);
        setConditionsData(mnaData.conditions);
        setDateData(mnaData.outDates);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch MNA data", error);
      }
    };
    getMnaData();
  }, [fetchRequired, id]);

  useEffect(() => {
    if (fetchRequired) {
      const updateMnaData = async () => {
        try {
          const mnaData = await fetchMnaData(id!, fetchFeatures);
          const sortedMnaData = {
            ...mnaData,
            outDates: [...(mnaData.outDates || [])].sort(
              (a, b) =>
                new Date(a.dateEvent).getTime() -
                new Date(b.dateEvent).getTime()
            ),
          };
          setData(sortedMnaData);
          setData(mnaData);
          setConditionsData(mnaData.conditions);
          setDateData(mnaData.outDates);
          setFetchRequired(false);
        } catch (error) {
          handleFormError(error);
        }
      };
      updateMnaData();
    }
  }, [fetchRequired, id]);

  const tabsContent = [
    {
      header: "What Matters",
      content: (
        <WhatMattersTab
          data={data}
          fetchMnaData={() => setFetchRequired(true)}
        />
      ),
    },
    {
      header: "Company Description",
      content: (
        <CompanyDescriptionTab
          data={data}
          fetchMnaData={() => setFetchRequired(true)}
        />
      ),
    },
    {
      header: "Blomberg info",
      content: (
        <table data-test-id="tab-content-mna" className="w-full">
          <caption className="text-left heading-bold text-title pt-4 px-4">
            Blomberg info
          </caption>
        </table>
      ),
    },
  ];

  const columnsInstance = new Columns();

  const fetchMnaNoteList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
      const getFetchMnaNoteList = new getApiData();
      return getFetchMnaNoteList.getNoteDomainApi2(
        String(id),
        pageIndex,
        pageSize,
        sortBy,
        "Mna"
      );
    },
    [id]
  );

  const openModal = (content: string) => {
    setContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <div
        className={`py-[24px] pr-[24px] ${
          isMenuOpen ? "pl-[308px]" : "pl-[84px]"
        } duration-500`}
      >
        <Breadcrumbs
          breadcrumbs={["MNA", `${data?.name || ""}`]}
          icon={""}
          updateItem={() => navigate(`/update-mna/${id}`)}
          addItem={() => navigate("/add-mna")}
        />
        <div className="dark:bg-table-dark rounded-md mt-[24px]">
          <div className="flex flex-col md:flex-row gap-[16px] mb-[16px]">
            <div className="md:w-1/2 flex flex-col gap-[16px]">
              <div>
                {/* COMPANY INFORMATION */}
                <CompanyInformation
                  data={data}
                  fetchMnaData={() => fetchMnaData(id!, fetchFeatures)}
                  sectorList={sectorList}
                />
              </div>
              <div className={"flex flex-col w-full gap-4"}>
                {/* CONTACTS */}
                <div className="w-full">
                  <Contacts
                    data={data}
                    fetchMnaData={() => fetchMnaData(id!, fetchFeatures)}
                    setData={setData}
                    setFetchRequired={setFetchRequired}
                    isContactDeleteModalOpen={isContactDeleteModalOpen}
                    setIsContactDeleteModalOpen={setIsContactDeleteModalOpen}
                    setDeleteContactId={setDeleteContactId}
                    onDeleteContact={() =>
                      onDeleteContact(
                        deleteContactId,
                        setLoading,
                        () => fetchMnaData(id!, fetchFeatures),
                        setIsContactDeleteModalOpen
                      )
                    }
                    isContactAdd={isContactAdd}
                    setLoading={setLoading}
                    setIsContactAdd={setIsContactAdd}
                  />
                </div>
                {/* DATES */}
                <div className="w-full ">
                  <Dates
                    data={data}
                    setFetchRequired={setFetchRequired}
                    setData={setData}
                    setDateData={setDateData}
                    dateData={dateData}
                    fetchMnaData={() => fetchMnaData(id!, fetchFeatures)}
                    safeId={safeId}
                  />
                </div>
              </div>
              {/* MNA BASICS */}
              <div className="border border-[#DCDBEE] bg-table-light rounded-md">
                <MnaBasics
                  data={data}
                  setData={setData}
                  setFetchRequired={setFetchRequired}
                  fetchMnaData={() => fetchMnaData(id!, fetchFeatures)}
                  id={id!}
                />
              </div>
            </div>
            {/* WHAT MATTERS / COMPANY DESCRIPTION / BLOMBERG INFO */}
            <div className="md:w-1/2 h-full">
              <div className="flex gap-3 mb-4">
                {tabsContent.map((tab, index) => (
                  <button
                    data-testid={`tab-button-mna-${index}`}
                    key={`${index}btnn`}
                    className={`min-w-[50px] h-[36px] px-2 ${
                      activeTab === index && "bg-[#E8F2FD] font-semibold"
                    } text-blue-primary border border-blue-primary text-sm rounded-md`}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.header}
                  </button>
                ))}
              </div>
              <div className="border border-[#E4E3ED] rounded-md h-full bg-table-light dark:bg-table-dark pb-2">
                {tabsContent[activeTab].content}
              </div>
              <div className="md:w-1/2 flex flex-col mt-4"></div>
              <div className="overflow-x-auto">
                {/* Holders Top 5 Holders */}
                <div className="min-w-full inline-block">
                  <TopFiveHolders />
                </div>
              </div>

              {data?.links?.length > 0 && (
                <div className="overflow-x-auto mt-4">
                  <div className="min-w-full inline-block align-middle">
                    {/* LINKS */}
                    <MnaLinks data={data} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <ConditionsMnaTable
            conditionsData={conditionsData}
            setLoading={setLoading}
            setConditionsData={setConditionsData}
            setFetchRequired={setFetchRequired}
          />
          <div className="overflow-x-auto mt-4">
            <div className="min-w-full inline-block align-middle bg-table-light dark:bg-table-dark">
              {/* NOTES */}
              <div className="table-container">
                <div className="min-w-full rounded-bl-lg rounded-br-lg">
                  <div className="text-left font-semibold  pt-4 pb-2 px-4">
                    <div className="flex items-center justify-between">
                      <div className="text-xs xs:text-xs text-title sm:text-sm md:text-sm lg:text-lg">
                        Notes
                      </div>
                    </div>
                  </div>
                  <div className="p-4">
                    <Table
                      fetchData={fetchMnaNoteList}
                      columns={columnsInstance.NotesListDetail()}
                      additionalDataKey="notes"
                      openModal={openModal}
                      typeForPagination="setNotesForMnaPageIndex"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto mt-4">
            <div className="min-w-full inline-block align-middle">
              <div className="table-container bg-table-light dark:bg-table-dark">
                <table className="min-w-full rounded-bl-lg rounded-br-lg">
                  <caption className="text-left text-title heading-bold pt-4 pb-2 px-4">
                    Attached files
                  </caption>
                  <thead className="">
                    <tr></tr>
                  </thead>
                  <tbody className="">
                    {data?.files?.length > 0 ? (
                      data?.files?.map((item: any, index: number) => (
                        <tr key={`files-${index}`}>
                          <td className="px-3.5 py-[20 px] text-[#000817] dark:text-white text-sm font-medium text-left">
                            <button
                              onClick={() =>
                                handleFileDownload(item.filename, item.fileId)
                              }
                            >
                              {item.filename}
                            </button>
                          </td>
                          <td className="px-4 pt-2  text-[#000817] dark:text-white text-sm font-medium text-left">
                            {getFileSize(item.size)}
                          </td>
                          <td className="px-4 pt-2  text-[#000817] dark:text-white text-sm font-medium text-left">
                            {moment(item?.dateModified).format("MM/DD/YYYY")}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td
                          colSpan={3}
                          className="text-primary font-bold text-sm p-4"
                        >
                          None
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
      {isModalOpen && content && (
        <div
          className={`fixed top-0 left-0 z-30 flex h-full min-h-screen w-full items-center justify-center px-4 py-5 ${
            isModalOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div
            className="absolute inset-0 transition-opacity bg-[#222222]"
            onClick={closeModal}
          ></div>
          <div className="pointer-events-auto relative flex h-[680px] w-[1440px] flex-col overflow-hidden rounded-md border-none bg-[#EAE5F7] bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div className="flex justify-end p-5">
              <img
                src={close}
                alt="close"
                onClick={() => closeModal()}
                className="button-action"
              />
            </div>
            <h2 className="px-[50px] pt-[18px] pb-5 text-[#fff] bg-primary-default dark:bg-black font-semibold text-lg">
              Description
            </h2>
            {/*Modal body*/}
            <div className="relative overflow-y-auto py-4 px-[50px]">
              <ul className="list-disc">
                <li
                  className=" text-[#393C51]  dark:text-white text-sm font-normal leading-5 capitalize mb-3"
                  dangerouslySetInnerHTML={{
                    __html: content || "",
                  }}
                />
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MnaDetails;
