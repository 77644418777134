interface EditableFieldMnaProps {
  content?: string;
}

const EditableFieldPlaceholder: React.FC<EditableFieldMnaProps> = ({
  content,
}) => {
  return (
    <div className="-ml-2 hover:bg-button-dark hover:bg-opacity-15 rounded-md px-2 ">
      <p className="py-2">{content}</p>
    </div>
  );
};

export default EditableFieldPlaceholder;
