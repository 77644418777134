// API
import { apiConfig } from "../components/ConfigurationApi/Configuration";
import {
  ConditionStatusDomainApi,
  ErrorLogDomainApi,
  FeatureDomainApi,
  FundamentalDomainApi,
  MnaDomainApi,
  NoteDomainApi,
  RegulatoryDomainApi,
  SectorDomainApi,
  TagDomainApi,
} from "../openapi";
// Helpers
import { getSortArray } from "../helpers/getSortArray";
// Types
import { Sort } from "../interfaces/pages/variedInterfaces";

interface ConditionStatus {
  id: number;
  status: string;
}

export class getApiData {
  async getMnaDomainApi(
    search: string,
    pageIndex: number,
    pageSize: number,
    sortBy: Sort[]
  ) {
    const api = new MnaDomainApi(apiConfig());
    const response = search
      ? await api.getMnas(
          `name:${search}`,
          pageIndex,
          pageSize,
          getSortArray(sortBy)
        )
      : await api.getMnas("", pageIndex, pageSize, getSortArray(sortBy));

    return response.data;
  }
  async getFeatureDomainApi() {
    const api = new FeatureDomainApi(apiConfig());
    const response = await api.getFeatures(undefined, undefined, undefined);

    const filteredOptions = response.data.content!.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));

    return filteredOptions;
  }
  async getSectorDomainApi(response: any) {
    const api2 = new SectorDomainApi(apiConfig());
    const response2 = await api2.getSectors(undefined, 0, 20, undefined);
    if (response?.data?.content) {
      const content =
        response.data.content?.map((item: any) => {
          response2.data.content?.forEach((sector: any) => {
            if (sector.id === item.sectorId) {
              item.sectorId = sector.name;
            }
          });
          return item;
        }) || [];

      return content;
    } else {
      return response2;
    }
  }
  async getCompanyDomainApi(id: number) {}
  async getTagDomainApi(inputValue: string) {
    const api = new TagDomainApi(apiConfig());
    const response = await api.getTags(
      `${inputValue}`,
      undefined,
      undefined,
      undefined
    );
    return response.data.content!.map((item: any) => ({
      value: item.id,
      label: item.tag,
    }));
  }
  async getNoteDomainApi(
    id: string | undefined,
    pageIndex: number,
    pageSize: number,
    sortBy: Sort[]
  ) {
    const api = new NoteDomainApi(apiConfig());
    const response = await api.getNotes(
      "",
      id,
      pageIndex,
      pageSize,
      getSortArray(sortBy)
    );
    return response.data;
  }
  async getErrorLogDomainApi(
    pageIndex: number,
    pageSize: number,
    sortBy: Sort[]
  ) {
    const sort = sortBy.length
      ? [`${sortBy[0].id},${sortBy[0].desc ? "DESC" : "ASC"}`]
      : "";
    const pageable = { page: pageSize, size: pageIndex, sort } as any;
    const api = new ErrorLogDomainApi(apiConfig());
    const response = await api.getErrorLogs(pageable);
    return response.data;
  }

  async getMnaDomainApi2(
    id: string | undefined,
    fetchFeatures: any,
    pagination: { pageSize: number; page: number }
  ) {
    const api = new MnaDomainApi(apiConfig());
    const response = await api.getMnaById(String(id));

    const api2 = new ConditionStatusDomainApi(apiConfig());
    const response2 = await api2.getConditionStatuses(
      undefined,
      pagination.page,
      pagination.pageSize,
      undefined
    );

    response.data.conditions?.map((item: any) => {
      const statusMatch = response2.data.content?.find(
        (status: any): status is ConditionStatus => status.id === item.statusId
      );

      if (statusMatch) {
        item.statusId = statusMatch.status;
      }
      return item;
    });

    const featureOptions = await fetchFeatures();
    const features =
      response.data?.features?.map((item: any) => {
        return {
          ...item,
          name: featureOptions?.find(
            (value: any) => value.value === item.featureId
          )?.label,
        };
      }) || [];

    return { ...response.data, features };
  }

  async getNoteDomainApi2(
    id: string,
    pageIndex: number,
    pageSize: number,
    sortBy: Sort[],
    module: string
  ) {
    const sort = sortBy.length
      ? [`${sortBy[0].id},${sortBy[0].desc ? "DESC" : "ASC"}`]
      : "";
    const pageable = { page: pageIndex, size: pageSize, sort } as any;

    const api = new MnaDomainApi(apiConfig());
    const funApi = new FundamentalDomainApi(apiConfig());
    const regApi = new RegulatoryDomainApi(apiConfig());

    const response =
      module === "Mna"
        ? await api.getMnaNotes(id, pageable)
        : module === "Fundamental"
        ? await funApi.getFundamentalNotes(id, pageable)
        : await regApi.getRegulatoryNotes(id, pageable);

    return response.data;
  }

  async getConditionsDomainApi(
    pageIndex: number,
    pageSize: number,
    sortBy: Sort[]
  ) {
    const sort = sortBy.length
      ? [`${sortBy[0].id},${sortBy[0].desc ? "DESC" : "ASC"}`]
      : "";
    const pageable = { page: pageSize, size: pageIndex, sort } as any;
    const api = new ConditionStatusDomainApi(apiConfig());
    const response = await api.getConditionStatuses(pageable);
    return response.data;
  }
}
