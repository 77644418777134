// src/redux/slices/fundamentalListPaginationSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PaginationState {
  pageIndex: number;
}

const initialState: PaginationState = {
  pageIndex: 0,
};

const paginationSlice = createSlice({
  name: "fundamentalListPagination",
  initialState,
  reducers: {
    setPageIndex(state, action: PayloadAction<number>) {
      state.pageIndex = action.payload;
    },
    resetPage(state) {
      state.pageIndex = 0;
    },
    nextPage(state) {
      state.pageIndex += 1;
    },
    previousPage(state) {
      if (state.pageIndex > 0) {
        state.pageIndex -= 1;
      }
    },
  },
});

export const {
  setPageIndex: setFundamentalListPageIndex,
  resetPage: resetFundamentalListPage,
  nextPage: nextFundamentalListPage,
  previousPage: previousFundamentalListPage,
} = paginationSlice.actions;

export default paginationSlice.reducer;
