// React
import React, { useState, useRef, useEffect } from "react";
// Third party
import moment from "moment/moment";
import { useParams } from "react-router-dom";
// Components
import ConditionsDeleteModal from "../Modal/DeleteModalMNAConditions";
import Tooltip from "../Tooltip/Tooltip";
import ConditionsAddCancelBtn from "./ConditionsAddCancelBtn";
// Utils
import { formatAndValidateDate } from "../../utils";
// Types
import { IConditions } from "../../types";
// Images
import PlusIcon from "../../assets/svg/plus-icon-add-button.svg";
import DeleteIcon from "../../assets/svg/delete.svg";
// Hooks
import { useConditionCategories } from "../../hooks/useConditionCategories";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";

// Redux
import {
  AddConditionsDomainApi,
  UpdateconditionsDomainApi,
  deleteConditionById,
} from "../../pages/MnaDetails/services/conditionsService";
import { handleFormError } from "../../utils/errorHandling";
// Types

interface ConditionsMnaTableProps {
  conditionsData: IConditions[];
  setLoading: (loading: boolean) => void;
  setConditionsData: any;
  setFetchRequired: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConditionsMnaTable: React.FC<ConditionsMnaTableProps> = ({
  conditionsData,
  setLoading,
  setConditionsData,
  setFetchRequired,
}) => {
  const { id } = useParams();
  const [editingRowId, setEditingRowId] = useState<string | undefined>("");
  const [editingRowData, setEditingRowData] = useState<IConditions | null>(
    null
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isAddingCondition, setIsAddingCondition] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");


  const [conditionToDelete, setConditionToDelete] = useState<
    string | undefined | any
  >("");
  const [newCondition, setNewCondition] = useState({
    version: 0,
    mnaId: id,
    conditionCategoryId: 0,
    categoryOtherComment: "",
    statusId: 2,
    filed: "",
    expectedConfirmed: "",
    commentary: "",
    expectedConfirmedFlag: false,
  });

  const [isHovered, setIsHovered] = useState(false);
  const { conditionCategories } = useConditionCategories();

  const handleAddConditionToggle = () => {
    if (isAddingCondition) {
      saveNewCondition();
    } else {
      setIsAddingCondition(true);
    }
  };

  const startEditing = (row: IConditions) => {
    setEditingRowId(row.id);
    setEditingRowData({ ...row });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (name === "conditionCategoryId" || name === "statusId") {
      setNewCondition((prev) => ({
        ...prev,
        [name]:
          name === "conditionCategoryId" || name === "statusId"
            ? Number(value)
            : value,
      }));
    } else {
      setNewCondition((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleToggleChangeAdd = () => {
    // @ts-ignore
    setNewCondition((prev) => ({
      ...prev,
      expectedConfirmedFlag: !prev?.expectedConfirmedFlag,
    }));
  };

  const handleToggleChangeEdit = () => {
    // @ts-ignore
    setEditingRowData((prev) => ({
      ...prev,
      expectedConfirmedFlag: !prev?.expectedConfirmedFlag,
    }));
  };

  const saveEditedRow = async () => {
    if (!editingRowData) return;
    setLoading(true);
    try {
      const body = {
        version: editingRowData.version,
        mnaId: editingRowData.mnaId,
        conditionCategoryId: editingRowData.conditionCategoryId,
        categoryOtherComment: editingRowData.categoryOtherComment,
        statusId:
          typeof editingRowData?.statusId === "number"
            ? editingRowData?.statusId
            : editingRowData?.statusId === "Complete"
            ? 1
            : 2,
        expectedConfirmed: editingRowData.expectedConfirmed,
        expectedConfirmedFlag: editingRowData.expectedConfirmedFlag !== null ? editingRowData.expectedConfirmedFlag : false,
        commentary: editingRowData.commentary,
        filed: editingRowData.filed,
      };

      await UpdateconditionsDomainApi(editingRowData.id, body);
      setConditionsData((prevData: IConditions[]) => {
        return prevData.map((condition: IConditions) => {
          return condition.id === editingRowData.id
            ? {
                ...condition,
                ...editingRowData,
                conditionCategoryId: editingRowData.conditionCategoryId,
                category: editingRowData.category,
              }
            : condition;
        });
      });
      setEditingRowId("");
      setEditingRowData(null);
    } catch (error) {
      console.error("Error updating condition:", error);
      alert("Failed to update condition");
    } finally {
      setLoading(false);
    }
  };

  const saveNewCondition = async () => {
    setLoading(true);
    try {
      await AddConditionsDomainApi(newCondition);
      const savedCondition = newCondition;
      setConditionsData((prev: IConditions[]) => {
        return [...prev, savedCondition];
      });
      setIsAddingCondition(false);
      setNewCondition({
        version: 0,
        mnaId: id,
        conditionCategoryId: 0,
        categoryOtherComment: "",
        statusId: 2,
        filed: "",
        expectedConfirmed: "",
        commentary: "",
        expectedConfirmedFlag: false,
      });
      setFetchRequired((prev: any) => !prev);
    } catch (error) {
      handleFormError(error)
    } finally {
      setLoading(false);
    }
  };

  const openDeleteModal = (conditionId: string | undefined) => {
    setConditionToDelete(conditionId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setConditionToDelete("");
    setIsDeleteModalOpen(false);
  };

  const handleDeleteCondition = async () => {
    setLoading(true);
    try {
      await deleteConditionById(conditionToDelete);
      setConditionToDelete(!conditionToDelete);
      setConditionsData((prev: any) =>
        prev.filter((item: any) => item.id !== conditionToDelete)
      );
    } catch (error) {
      console.error("Error deleting condition:", error);
      alert("Failed to delete condition");
    } finally {
      closeDeleteModal();
      setLoading(false);
    }
  };

  const sortedData = conditionsData.sort((a: any, b: any) => {
    if (!sortField) return 0;

    if (a[sortField] < b[sortField]) return sortOrder === "asc" ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortOrder === "asc" ? 1 : -1;
    return 0;
  });

  const toggleRef = useRef<HTMLDivElement>(null);
  const confirmButtonRef = useRef<HTMLButtonElement>(null);
  const editingRowRef = useRef<HTMLDivElement>(null);
  const addingRowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        editingRowRef.current &&
        !editingRowRef.current.contains(event.target as Node) &&
        !confirmButtonRef.current?.contains(event.target as Node)
      ) {
        setEditingRowId("");
        setEditingRowData(null);
      }

      if (
        addingRowRef.current &&
        !addingRowRef.current.contains(event.target as Node) &&
        !confirmButtonRef.current?.contains(event.target as Node)
      ) {
        setIsAddingCondition(false);
        setNewCondition({
          version: 0,
          mnaId: id,
          conditionCategoryId: 0,
          categoryOtherComment: "",
          statusId: 2,
          filed: "",
          expectedConfirmed: "",
          commentary: "",
          expectedConfirmedFlag: false,
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [id]);

  const handleSort = (field: any) => {
    if (sortField === field) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  return (
    <div className="overflow-x-auto mt-4">
      <div className="min-w-full inline-block align-middle">
        {/* CONDITIONS */}
        <div className="pt-[18px] px-[10px] border-[1px] border-[#DCDBEE] bg-table-light pb-[10px] rounded-[8px] ">
          <div className="flex items-center justify-between">
            <div className="text-[20px] text-[#3B97FF] font-extrabold">
              Conditions
            </div>
            {!isAddingCondition && !editingRowId && (
              <button
                ref={confirmButtonRef}
                type="button"
                onClick={handleAddConditionToggle}
                className="w-[113px] h-[28px] flex items-center justify-between text-[#3B97FF] bg-[#EDF6FF] rounded-[7px] border-[1px] text-[10px] px-[10px] text-center font-normal"
              >
                <img src={PlusIcon} alt="Add" className="w-[14px] h-[14px]" />
                Add Conditions
              </button>
            )}
          </div>
          <div className="border-[1px] border-[#E4E5E9] rounded-[12px] mt-[14px] ">
            {/* Header */}
            <div
              className={`bg-[#FBFBFB] grid grid-cols-[230px_1fr_169px_169px_147px_80px] rounded-tl-[12px] rounded-tr-[12px] h-[41px] items-center mb-[4px] ${
                (isAddingCondition || editingRowData) &&
                "grid-cols-[minmax(150px,_230px)_1fr_minmax(140px,_169px)_minmax(140px,_169px)_minmax(130px,_147px)_minmax(130px,_147px)_80px] "
              }`}
            >
              <div
                className="pt-[12px] pb-[12px] pl-[15px] text-[12px] text-start flex items-center gap-1 cursor-pointer"
                onClick={() => handleSort("conditionCategoryId")}
              >
                <span>Name</span>
                <span>
                  {sortField === "conditionCategoryId" &&
                    (sortOrder === "asc" ? "↑" : "↓")}
                </span>
              </div>
              <div
                className="pt-[12px] pb-[12px] pl-[15px] text-[12px] text-start min-w-[230px] flex items-center gap-1 cursor-pointer"
                onClick={() => handleSort("commentary")}
              >
                <span>Commentary</span>
                <span>
                  {sortField === "commentary" &&
                    (sortOrder === "asc" ? "↑" : "↓")}
                </span>
              </div>
              <div
                className={`pt-[12px] pb-[12px] text-[12px] pl-[30px] flex items-center gap-[12px] cursor-pointer`}
                onClick={() => handleSort("filed")}
              >
                <span>Filed/File-By</span>
                <span>
                  {sortField === "filed" && (sortOrder === "asc" ? "↑" : "↓")}
                </span>
              </div>
              <div
                className={`pt-[12px] pb-[12px] text-[12px] text-start flex items-center gap-[12px] cursor-pointer`}
                onClick={() => handleSort("expectedConfirmed")}
              >
                <span>Expected/Confirmed</span>
                <span>
                  {sortField === "expectedConfirmed" &&
                    (sortOrder === "asc" ? "↑" : "↓")}
                </span>
              </div>
              {(editingRowId || isAddingCondition) && (
                <div
                  className={`pt-[12px] pb-[12px] text-[12px] text-start flex items-center gap-[12px] cursor-pointer`}
                >
                  <span></span>
                </div>
              )}
              <div
                className={`pt-[12px] pb-[12px] text-[12px] flex items-center gap-[12px] cursor-pointer`}
                onClick={() => handleSort("statusId")}
              >
                <span>Status</span>
                <span>
                  {sortField === "statusId" &&
                    (sortOrder === "asc" ? "↑" : "↓")}
                </span>
              </div>

              <div className="pt-[12px] pb-[12px] text-[12px] text-center"></div>
            </div>

            {/* Body */}
            <div>
              {isDeleteModalOpen && (
                <ConditionsDeleteModal
                  title="Are you sure you want to delete this condition?"
                  onClose={closeDeleteModal}
                  onConfirm={handleDeleteCondition}
                />
              )}
              {isAddingCondition && (
                <div className="relative">
                  <div
                    ref={addingRowRef}
                    className={`grid grid-cols-[230px_1fr_169px_169px_147px_80px] items-center  ${
                      (isAddingCondition || editingRowData) &&
                      "grid-cols-[minmax(150px,_230px)_1fr_minmax(140px,_169px)_minmax(140px,_169px)_minmax(130px,_147px)_minmax(130px,_147px)_80px] h-max"
                    }`}
                  >
                    <div>
                      <select
                        name="conditionCategoryId"
                        value={newCondition.conditionCategoryId}
                        onChange={handleInputChange}
                        className="w-full px-[8px] py-[4px] text-[14px] border-[1px] border-[#E4E5E9] rounded-[4px] focus:outline-none focus:border-[#3B97FF]"
                      >
                        <option value={0}>Select Category</option>
                        {conditionCategories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <input
                        type="text"
                        name="commentary"
                        value={newCondition.commentary}
                        onChange={handleInputChange}
                        autoComplete="off"
                        className="w-full px-[8px] py-[4px] text-[14px] border-[1px] border-[#E4E5E9] rounded-[4px] focus:outline-none focus:border-blue-primary min-w-[230px]"
                      />
                    </div>
                    <div>
                      <input
                        type="date"
                        name="filed"
                        value={newCondition.filed}
                        onChange={handleInputChange}
                        className="w-full pl-[30px] px-[8px] !py-[3px] text-[14px] border-[1px] border-[#E4E5E9] rounded-[4px] focus:outline-none focus:border-blue-primary "
                      />
                    </div>
                    <div className="text-center">
                      <input
                        type="date"
                        name="expectedConfirmed"
                        value={newCondition.expectedConfirmed}
                        onChange={handleInputChange}
                        className="w-full text-[] pl-[30px] px-[8px] !py-[3px] text-[14px] border-[1px] border-[#E4E5E9] rounded-[4px] focus:outline-none focus:border-blue-primary"
                      />
                    </div>
                    <div
                      ref={toggleRef}
                      className="relative"
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <div
                        onClick={handleToggleChangeEdit}
                        className="cursor-pointer flex justify-center items-center gap-2 w-full"
                      >
                        {editingRowData?.expectedConfirmedFlag ? (
                          <FontAwesomeIcon
                            icon={faToggleOn}
                            className="text-[#3B97FF] text-[24px]"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faToggleOff}
                            className="text-[#181C2E] text-[24px]"
                          />
                        )}
                      </div>
                      {isHovered && (
                        <Tooltip
                          targetRef={toggleRef}
                          text={
                            editingRowData?.expectedConfirmedFlag
                              ? "When toggled, Expected/Confirmed date is marked as confirmed"
                              : "When not toggled, Expected/Confirmed date remains as expected."
                          }
                        />
                      )}
                    </div>
                    <div>
                      <select
                        name="statusId"
                        value={newCondition.statusId}
                        onChange={handleInputChange}
                        className="w-full pr-[8px] py-[4px] text-[14px] border-[1px] border-[#E4E5E9] rounded-[4px] focus:outline-none focus:border-blue-primary"
                      >
                        <option value={2}>Pending</option>
                        <option value={1}>Completed</option>
                      </select>
                    </div>
                    <div></div>
                  </div>
                  <ConditionsAddCancelBtn
                    editingRowId={editingRowId}
                    saveEditedRow={saveEditedRow}
                    handleAddConditionToggle={handleAddConditionToggle}
                    setIsAddingCondition={setIsAddingCondition}
                    confirmButtonRef={confirmButtonRef} // Pass the ref here
                  />
                </div>
              )}
              {sortedData?.map((item: IConditions, index: number) =>
                editingRowId === item.id ? (
                  <div className="relative">
                    <div
                      key={`${index}conditions`}
                      ref={editingRowRef}
                      className={`w-full grid grid-cols-[minmax(150px,_230px)_1fr_minmax(140px,_169px)_minmax(140px,_169px)_minmax(130px,_147px)_80px] items-center min-h-max ${
                        (isAddingCondition || editingRowData) &&
                        "grid-cols-[minmax(150px,_230px)_1fr_minmax(140px,_169px)_minmax(140px,_169px)_minmax(130px,_147px)_minmax(130px,_147px)_80px]"
                      }`}
                    >
                      <div>
                        <select
                          name="conditionCategoryId"
                          value={editingRowData?.conditionCategoryId || ""}
                          className="w-full px-[8px] py-[4px] text-[14px] border-[1px] border-[#E4E5E9] rounded-[4px] focus:outline-none focus:border-[#3B97FF]"
                          onChange={(e) =>
                            setEditingRowData((prev) =>
                              prev
                                ? {
                                    ...prev,
                                    conditionCategoryId: Number(e.target.value),
                                  }
                                : null
                            )
                          }
                        >
                          <option value={0}>Select Category</option>
                          {conditionCategories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <input
                          type="text"
                          name="commentary"
                          value={editingRowData?.commentary || ""}
                          onChange={(e) =>
                            setEditingRowData((prev) =>
                              prev
                                ? { ...prev, commentary: e.target.value }
                                : null
                            )
                          }
                          autoComplete="off"
                          className="w-full px-[8px] py-[4px] text-[14px] border-[1px] border-[#E4E5E9] rounded-[4px] focus:outline-none focus:border-blue-primary min-w-[230px]"
                        />
                      </div>
                      <div>
                        <input
                          type="date"
                          name="filed"
                          value={editingRowData?.filed || ""}
                          onChange={(e) =>
                            setEditingRowData((prev) =>
                              prev ? { ...prev, filed: e.target.value } : null
                            )
                          }
                          className="w-full pl-[30px] px-[8px] !py-[3px] text-[14px] border-[1px] border-[#E4E5E9] rounded-[4px] focus:outline-none focus:border-blue-primary"
                        />
                      </div>
                      <div className="text-center">
                        <input
                          type="date"
                          name="expectedConfirmed"
                          value={editingRowData?.expectedConfirmed || ""}
                          onChange={(e) =>
                            setEditingRowData((prev) =>
                              prev
                                ? { ...prev, expectedConfirmed: e.target.value }
                                : null
                            )
                          }
                          className="w-full pl-[30px] px-[8px] !py-[3px] text-[14px] border-[1px] border-[#E4E5E9] rounded-[4px] focus:outline-none focus:border-blue-primary"
                        />
                      </div>
                      <div
                        ref={toggleRef}
                        className="relative"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        <div
                          onClick={handleToggleChangeEdit}
                          className="cursor-pointer flex justify-center items-center gap-2 w-full"
                        >
                          {editingRowData?.expectedConfirmedFlag ? (
                            <FontAwesomeIcon
                              icon={faToggleOn}
                              className="text-[#3B97FF] text-[24px]"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faToggleOff}
                              className="text-[#181C2E] text-[24px]"
                            />
                          )}
                        </div>
                        {isHovered && (
                          <Tooltip
                            targetRef={toggleRef}
                            text={
                              editingRowData?.expectedConfirmedFlag
                                ? "When toggled, Expected/Confirmed date is marked as confirmed"
                                : "When not toggled, Expected/Confirmed date remains as expected."
                            }
                          />
                        )}
                      </div>
                      <div>
                        <select
                          name="statusId"
                          value={
                            typeof editingRowData?.statusId === "number"
                              ? editingRowData?.statusId
                              : editingRowData?.statusId === "Complete"
                              ? 1
                              : 2
                          }
                          onChange={(e) =>
                            setEditingRowData((prev) =>
                              prev
                                ? { ...prev, statusId: Number(e.target.value) }
                                : null
                            )
                          }
                          className="w-full pr-[8px] py-[4px] text-[14px] border-[1px] border-[#E4E5E9] rounded-[4px] focus:outline-none focus:border-blue-primary"
                        >
                          <option value={2}>Pending</option>
                          <option value={1}>Complete</option>
                        </select>
                      </div>
                      <div>
                        <div className="flex justify-center cursor-pointer">
                          <img
                            src={DeleteIcon}
                            alt="delete"
                            onClick={() => openDeleteModal(item.id)}
                          />
                        </div>
                      </div>
                    </div>
                    {(isAddingCondition || editingRowId) && (
                      <ConditionsAddCancelBtn
                        editingRowId={editingRowId}
                        saveEditedRow={saveEditedRow}
                        handleAddConditionToggle={handleAddConditionToggle}
                        setIsAddingCondition={setIsAddingCondition}
                        confirmButtonRef={confirmButtonRef}
                      />
                    )}
                  </div>
                ) : (
                  <div
                    key={`${index}conditions`}
                    onDoubleClick={() => startEditing(item)}
                    className={`w-full grid grid-cols-[230px_1fr_169px_169px_147px_80px] ${
                      (isAddingCondition || editingRowData) &&
                      "grid-cols-[minmax(150px,_230px)_1fr_minmax(140px,_169px)_minmax(140px,_169px)_minmax(130px,_147px)_minmax(130px,_147px)_80px]"
                    } items-center h-max py-1`}
                  >
                    <div className="text-[14px] pl-[15px] text-ellipsis whitespace-nowrap">
                      {conditionCategories.find(
                        (category) => category.id === item.conditionCategoryId
                      )?.name || item.category?.name}
                    </div>
                    <div className="text-[14px] pl-[15px] min-w-[230px]">
                      {item?.commentary}
                    </div>
                    <div className="text-[14px] pl-[30px]">
                      {item?.filed === null
                        ? null
                        : moment(formatAndValidateDate(item?.filed)).format(
                            "MM/DD/YYYY"
                          )}
                    </div>
                    <div className="text-[14px] ps-0.5">
                      {item?.expectedConfirmed === null
                        ? null
                        : moment(
                            formatAndValidateDate(item?.expectedConfirmed)
                          ).format("MM/DD/YYYY")}
                      {"\u00A0"}
                      {item?.expectedConfirmed === null
                        ? null
                        : item?.expectedConfirmedFlag === true
                        ? "(C)"
                        : "(E)"}
                    </div>
                    {(isAddingCondition || editingRowData) && (
                      <div className="text-[14px] "></div>
                    )}
                    <div className="text-[14px] text-start">
                      {typeof item?.statusId === "string"
                        ? item?.statusId
                        : item?.statusId === 1
                        ? "Complete"
                        : "Pending"}
                    </div>
                    <div className="flex justify-center cursor-pointer">
                      <img
                        src={DeleteIcon}
                        alt="delete"
                        onClick={() => openDeleteModal(item.id)}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConditionsMnaTable;
