// React
import React from "react";
// Components
import Table from "../../../../components/Tables/RecentEvent";
import { Columns } from "../../../../components/Columns/Columns";

interface NotesProps {
  data: any;
  openModal: (content: string) => void;
}

const Notes: React.FC<NotesProps> = ({ data, openModal }) => {
  const columnsInstance = new Columns();

  return (
    <div className="table-container" data-testid="notes-section">
      <div className="min-w-full rounded-bl-lg rounded-br-lg">
        <div className="text-left font-semibold  pt-4 pb-2 px-4">
          <div className="flex items-center justify-between">
            <div className="text-xs xs:text-xs text-title sm:text-sm md:text-sm lg:text-lg">
              Notes
            </div>
          </div>
        </div>
        <div className="p-4">
          <Table
            dataFundamental={data.notes}
            columns={columnsInstance.NotesListDetail()}
            additionalDataKey="notes"
            openModal={openModal}
          />
          {}
        </div>
      </div>
    </div>
  );
};

export default Notes;
