import React, { useEffect, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { setErrorsListPageIndex } from "../../features/errorListPagination";
import { setFilesListPageIndex } from "../../features/filesListPagination";
import { setFundamentalListPageIndex } from "../../features/fundamentalListPagination";
import { setMnaListPageIndex } from "../../features/mnaListPagination";
import { setNotesForMnaPageIndex } from "../../features/notesForMnaPagination";
import { setNotesListPageIndex } from "../../features/notesListPagination";
import { setRegulatoryListPageIndex } from "../../features/regulatoryListPagination";
import { RootState } from "../../store/store";
import { setNotesForFundamentalPageIndex } from "../../features/notesForFundamentalPagination";
import { PaginationActionType } from "../../types/pagination-types";


interface PaginationProps {
  pageCount: number;
  pageIndex: number;
  gotoPage?: any;
  typeForPagination?: PaginationActionType;
}

const Pagination: React.FC<PaginationProps> = ({
  pageCount,
  typeForPagination,
}) => {
  const [inputPage, setInputPage] = useState<string>("");
  const [showInput, setShowInput] = useState<number | null>(null);

  useEffect(() => {
    setInputPage("");
  }, [showInput]);

  const dispatch = useDispatch();
  const setPageIndexActionMap: Record<PaginationActionType, any> = {
    setErrorsListPageIndex,
    setFilesListPageIndex,
    setFundamentalListPageIndex,
    setMnaListPageIndex,
    setNotesForMnaPageIndex,
    setNotesListPageIndex,
    setRegulatoryListPageIndex,
    setNotesForFundamentalPageIndex,
  };
  const paginationStateMap: Record<PaginationActionType, keyof RootState> = {
    setErrorsListPageIndex: "errorsListPagination",
    setFilesListPageIndex: "filesListPagination",
    setFundamentalListPageIndex: "fundamentalListPagination",
    setMnaListPageIndex: "mnaListPagination",
    setNotesForMnaPageIndex: "notesForMnaPagination",
    setNotesListPageIndex: "notesListPagination",
    setRegulatoryListPageIndex: "regulatoryListPagination",
    setNotesForFundamentalPageIndex: "notesForFundamental",
  };

  const selectedPaginationSlice = paginationStateMap[typeForPagination!];

  const pageIndex = useSelector((state: RootState) => {
    const paginationState = state[selectedPaginationSlice];
    if (paginationState && "pageIndex" in paginationState) {
      return paginationState.pageIndex;
    }
    return 0;
  });

  const setPageIndexAction = typeForPagination
    ? setPageIndexActionMap[typeForPagination]
    : null;

  const handlePageChange = (newPage: number) => {
    if (setPageIndexAction) {
      dispatch(setPageIndexAction(newPage));
    }
  };



  return (
    <>
      {Array.from({ length: pageCount }, (_, i) => {
        const pageNumber = i + 1;
        if (
          pageNumber === 1 ||
          pageNumber === pageCount ||
          pageNumber === pageIndex + 1 ||
          pageNumber === pageIndex + 2 ||
          pageNumber === pageIndex
        ) {
          return (
            <button
              key={i}
              onClick={() => handlePageChange(pageNumber - 1)}
              data-testid={`page-button-${pageNumber}`}
              className={`px-[8px] pt-[4px] pb-[4px] flex items-center justify-center rounded-md ${
                pageNumber === pageIndex + 1
                  ? "bg-[#3B97FF] text-white"
                  : "bg-transparent text-[#A1AEB7]"
              }`}
            >
              <span>{pageNumber}</span>
            </button>
          );
        }
        if (pageNumber === 2 && pageIndex > 3) {
          return showInput === pageNumber ? (
            <div
              key="input-ellipsis-start"
              className="relative w-7 h-8 flex items-center justify-center"
            >
              <input
                type="number"
                className="absolute w-full h-full text-center dark:bg-b-secondary-dark dark:border-white text-[#3B97FF] focus:outline-none focus:ring-0 appearance-none"
                min="1"
                max={pageCount}
                value={inputPage}
                onChange={(e) => setInputPage(e.target.value)}
                onBlur={() => setShowInput(null)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    const page = parseInt(inputPage, 10) - 1;
                    if (page >= 0 && page < pageCount) handlePageChange(page);
                    setInputPage("");
                    setShowInput(null);
                  }
                }}
              />
              {!inputPage && (
                <span className="absolute text-[#3B97FF] pointer-events-none">
                  ___
                </span>
              )}
            </div>
          ) : (
            <span
              key="ellipsis-start"
              className="px-1 text-[#A1AEB7] dark:text-primary-light cursor-pointer"
              onClick={() => setShowInput(pageNumber)}
            >
              ...
            </span>
          );
        }
        if (pageNumber === pageCount - 1 && pageIndex < pageCount - 3) {
          return showInput === pageNumber ? (
            <div
              key="input-ellipsis-end"
              className="relative w-7 h-8 flex items-center justify-center"
            >
              <input
                type="number"
                className="absolute w-full h-full text-center dark:bg-b-secondary-dark dark:border-white text-[#3B97FF] focus:outline-none focus:ring-0 appearance-none"
                min="1"
                max={pageCount}
                value={inputPage}
                onChange={(e) => setInputPage(e.target.value)}
                onBlur={() => setShowInput(null)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    const page = parseInt(inputPage, 10) - 1;
                    if (page >= 0 && page < pageCount) handlePageChange(page);
                    setInputPage("");
                    setShowInput(null);
                  }
                }}
              />
              {!inputPage && (
                <span className="absolute text-[#3B97FF] pointer-events-none">
                  ___
                </span>
              )}
            </div>
          ) : (
            <span
              key="ellipsis-end"
              className="px-1 text-[#A1AEB7] dark:text-primary-light cursor-pointer"
              onClick={() => setShowInput(pageNumber)}
            >
              ...
            </span>
          );
        }
        return null;
      })}
    </>
  );
};

export { Pagination };
