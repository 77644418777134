// React
import React, { useState } from "react";
// Third party
import {useParams } from "react-router-dom";
// Components
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import Loader from "../../../../components/Loader";
import FundamentalForm from "./FundamentalForm";
// Types
import {HandleMenuProps} from "../../../../interfaces/pages/variedInterfaces";

const FundamentalAddEdit: React.FC<HandleMenuProps> = ({
  isMenuOpen,
}) => {
  const { id } = useParams();
  const [serverError, setServerError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      <div
        className={`py-[30px] pr-[30px] ${isMenuOpen ? "pl-[314px]" : "pl-[90px]"
          } duration-500`}
      >
        {/* Breadcrumbs component */}
        <Breadcrumbs
          breadcrumbs={[id ? "Update Fundamental" : "Add New Fundamental"]}
        />
        <div>
          {/* Form */}
          <form onChange={() => setServerError(null)}>
            <FundamentalForm setLoading={setLoading} isMenuOpen={isMenuOpen} loading={loading} serverError={serverError} setServerError={setServerError}/>
          </form>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
    </>
  );
};

export default FundamentalAddEdit;
