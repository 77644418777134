// React
import React from 'react';

interface ErrorKeyValuePairListProps {
  data: {
    requestHeaders?: string;
    stackTrace?: string;
    [key: string]: string | undefined;
  };
}

const ErrorKeyValuePairList: React.FC<ErrorKeyValuePairListProps> = ({ data }) => {
  // Split the request headers into key-value pairs
  const keyValuePairs = data?.requestHeaders?.split("\n");

  return (
    <>
      {Object.entries(data)
        .filter(([key]) => !["requestHeaders", "stackTrace"].includes(key))
        .map(([key, value]) => (
          <li className="w-full flex" key={key}>
            <div className="w-1/4 px-4 py-2 border-r border-[#DDDBEE] font-courier">{key}</div>
            <div className="w-3/4 px-4 py-2 font-courier break-words">{value}</div>
          </li>
        ))}

      {keyValuePairs?.map((pair, index) => {
        const [key, value] = pair.split(":");
        return pair && (
          <li className="w-full flex" key={index}>
            <div className="w-1/4 px-4 py-2 border-r border-[#DDDBEE] font-courier">{key?.trim()}</div>
            <div className="w-3/4 px-4 py-2 font-courier break-words">{value?.trim()}</div>
          </li>
        );
      })}

      {data?.stackTrace && (
        <li className="w-full flex">
          <div className="w-1/4 px-4 py-2 border-r border-[#DDDBEE] font-courier">
            stackTrace
          </div>
          <div className="w-3/4 px-4 py-2 font-courier break-words">
            {data?.stackTrace}
          </div>
        </li>
      )}
    </>
  );
};

export default ErrorKeyValuePairList;
