// React
import React, { useEffect, useRef, useState } from "react";
// Third party
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import toast from "react-hot-toast";
// Components
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import { CustomErrorModal } from "../components/Modal/customModal";
import InputField from "../components/CommonInputs";
import Loader from "../components/Loader";
import TextEditor from "../components/TextEditor";
import {showAlert} from "../components/Modal/AlertModal";
import { apiConfig } from "../components/ConfigurationApi/Configuration";
// OpenAPI
import { RegulatoryDomainApi } from "../openapi";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
// Types
import { ErrorFileData } from "../interfaces/pages/Errors";
import { HandleMenuProps } from "../interfaces/pages/variedInterfaces";

const initialValues = {
  name: "",
  mandate: "",
  purpose: "",
  timing: "",
  description: "",
  commentary: "",
  process: "",
};

const RegulatoryAddEdit: React.FC<HandleMenuProps> = ({
    isMenuOpen,
  }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [serverError, setServerError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const $businessDescription = useRef<ReactQuill>(null);
  const $process = useRef<ReactQuill>(null);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const [editorData, setEditorData] = useState({ ref: null, keyName: '' });


  useEffect(() => {
    if (!id) return;
    fetchRegulatoryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchRegulatoryData = async () => {
    setLoading(true);
    try {
      const api = new RegulatoryDomainApi(apiConfig());

      const response = await api.getRegulatoryById(String(id));
      if (response.data) {
        const data = response.data as any;

        delete data.dateCreated;
        delete data.dateModified;
        delete data.createdBy;
        delete data.lastModifiedBy;
        delete data.links;
        delete data.files;
        delete data.notes;
        delete data.whatMatters;

        formik.setValues(data);
      }
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  // Formik hook for form management
  const formik: any = useFormik({
    initialValues,
    onSubmit: async (values: any, { setSubmitting, setErrors }) => {
      values[editorData.keyName] = editorData.ref
      setLoading(true);
      const data: any = {
        ...formik.values,
        version: 0,
        mnaId: values.mnaId?.value,
      };
      if (submitButtonRef.current && !submitButtonRef.current.disabled) {
        submitButtonRef.current.disabled = true;
      try {
        // API call
        const api = new RegulatoryDomainApi(apiConfig());
        id
          ? await api.updateRegulatory(String(id), data)
          : await api.createRegulatory(data);
        showAlert({
          message: `Regulatory  ${id ? 'updated' : 'created'} successfully!`,
          type: 'success'
        })
        navigate("/regulatory");
      } catch (error: any) {
        showAlert({
          message: 'Please check the correctness of the fields',
          type: 'error'
        });
        if (error.response) {
          if (
            error.response.data?.exception &&
            !error.response.data?.fieldErrors?.length
          ) {
            toast.custom(
              (t) => <CustomErrorModal visible={t.visible} error={error} />,
              {
                duration: 500,
              }
            );
          }

          if (error.response.data?.fieldErrors?.length) {
            const errorsObject = {} as any;
            error.response.data?.fieldErrors.forEach((error: ErrorFileData) => {
              errorsObject[error.field] = error.defaultMsg;
            });
            setErrors(errorsObject);
            setServerError(errorsObject);
          }
        } else {
          // Handle other errors
          console.error("Error submitting form:", error.message);
        }
      } finally {
        setSubmitting(false);
        setLoading(false);
        submitButtonRef.current.disabled = false
      }
    }
    },
  });

  const focusTextEditor = (
    e: React.KeyboardEvent<HTMLDivElement>,
    reference: React.RefObject<ReactQuill>
  ) => {
    if (e.key === "Tab") {
      e.preventDefault();
      e.stopPropagation();
      reference.current && reference.current.focus();
    }
  };

  const handleEditorRef = (ref:any) => {
    setEditorData(ref);
  };

  return (
    <React.Fragment>
      <div
        className={`py-[30px] pr-[30px] ${
          isMenuOpen ? "pl-[314px]" : "pl-[90px]"
        } duration-500`}
      >
        {/* Breadcrumbs component */}
        <Breadcrumbs
          breadcrumbs={[id ? "Update Regulatory" : "Add New Regulatory"]}
        />

        <div>
          {/* Form */}
          <form onChange={() => setServerError(null)}>
            <div className="py-6">
              <div className="mb-8 flex flex-col gap-[30px] xl:flex-row">
                {/* Name */}

                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Name"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Name"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                  />
                </div>

                {/* Purpose */}

                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Purpose"
                    id="purpose"
                    name="purpose"
                    type="text"
                    placeholder="Purpose"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                  />
                </div>

                {/* Mandate */}
                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Mandate"
                    id="mandate"
                    name="mandate"
                    type="text"
                    placeholder="Mandate"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                    last
                  />
                </div>
              </div>

              <div className="w-full mb-8">
                {/* Description */}

                <div className="w-full xl:w-full">
                  <InputField
                    label="Description"
                    id="description"
                    name="description"
                    type="text"
                    placeholder="Description"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    last
                    bgColor={"#fff"}
                  />
                </div>
              </div>

              <div className="mb-8 flex flex-col gap-[30px] xl:flex-row">
                {/* Timing */}

                <div className="w-full xl:w-1/2">
                  <InputField
                    label="Timing"
                    id="timing"
                    name="timing"
                    type="text"
                    placeholder="Timing"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                  />
                </div>

                {/* Commentary  */}

                <div className="w-full xl:w-1/2">
                  <InputField
                    label="Commentary "
                    id="commentary"
                    name="commentary"
                    type="text"
                    placeholder="Commentary "
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                    last
                  />
                </div>
              </div>

              {/* Process */}
              <div className="mb-8">
                <div
                  className="relative"
                  onKeyDownCapture={(e) =>
                    focusTextEditor(e, $businessDescription)
                  }
                >
                  <TextEditor
                    formik={formik}
                    error={serverError?.content}
                    id="process"
                    quillRef={$process}
                    name="process"
                    label="Process"
                    onEditorRef={handleEditorRef}
                  />
                </div>
              </div>

              <div className="flex justify-end gap-[30px] mt-[80px]">
                <div>
                  <button
                    type="button"
                    className="py-[18px] border border-secondary text-secondary text-base font-medium rounded-full px-[116px]"
                    onClick={() => navigate("/regulatory")}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    name="Save"
                    ref={submitButtonRef}
                    onClick={formik.handleSubmit}
                    className="py-[18px] border border-secondary text-[#fff] bg-primary-default text-base font-medium rounded-full px-[103px]"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
    </React.Fragment>
  );
};

export default RegulatoryAddEdit;
