import React, { useEffect, RefObject } from "react";
// Images
import checkmark from "../../assets/images/checkmark.png";
import close from "../../assets/svg/close.svg";

interface ConditionsAddCancelBtnProps {
  editingRowId: string | undefined;
  saveEditedRow: () => void;
  handleAddConditionToggle: () => void;
  setIsAddingCondition: React.Dispatch<React.SetStateAction<boolean>>;
  confirmButtonRef: RefObject<HTMLButtonElement>;
}

const ConditionsAddCancelBtn: React.FC<ConditionsAddCancelBtnProps> = ({
  editingRowId,
  saveEditedRow,
  handleAddConditionToggle,
  setIsAddingCondition,
  confirmButtonRef,
}) => {
  // Add Enter key support
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        if (editingRowId) {
          saveEditedRow();
        } else {
          handleAddConditionToggle();
        }
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup on unmount
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [editingRowId, saveEditedRow, handleAddConditionToggle]);

  return (
    <div className="flex w-full items-end justify-end absolute  z-[9990] pe-2">
      <div className="flex items-center gap-3 w-max justify-end px-4 py-2 mt-2 shadow-xl bg-white border border-[#f2f3f4ed] rounded-md ">
        {/* Confirm Button */}
        <button
          ref={confirmButtonRef}
          type="button"
          onClick={() => {
            if (editingRowId) {
              saveEditedRow();
            } else {
              handleAddConditionToggle();
            }
          }}
          className="rounded-sm p-1 z-10 bg-white shadow-md hover:shadow-xl transition-shadow duration-200"
        >
          <img src={checkmark} alt="Add or Confirm" className="w-5 h-5" />
        </button>

        {/* Cancel Button */}
        <button
          onClick={() => {
            setIsAddingCondition(false);
          }}
          className="rounded-sm p-1 z-10 bg-white shadow-md hover:shadow-xl transition-shadow duration-200"
        >
          <img src={close} alt="Cancel" className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default ConditionsAddCancelBtn;
