// React
import React, { useCallback, useState } from "react";
// Third party
import { useNavigate } from "react-router-dom";
// Images
import RegulatoryIcon from "../../assets/svg/Regulatory.svg";
// Types
import { HandleMenuProps, Sort } from "../../interfaces/pages/variedInterfaces";
// Components
import { Columns } from "../../components/Columns/Columns";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Table from "../Fundamental/components/MnaFundamentalTable";
// Services
import { fetchRegulatoryListService } from "./services/regulatoryService";


const RegulatoryList: React.FC<HandleMenuProps> = ({
  isMenuOpen,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const fetchRegulatoryList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
      return await fetchRegulatoryListService(pageIndex, pageSize, sortBy, setLoading);
    },
    [search]
  );

  const columnsInstance = new Columns();

  return (
    <React.Fragment>
      <div
        className={`py-[30px] pr-[24px] ${isMenuOpen ? "pl-[314px]" : "pl-[84px]"
          } duration-500`}
      >
        <Breadcrumbs
          breadcrumbs={["Regulatory"]}
          icon={RegulatoryIcon}
          addItem={() => navigate("/regulatory/add")}
        />
        <div className="mt-[31px]">
          <Table
            columns={columnsInstance.RegulatoryList(navigate)}
            fetchData={fetchRegulatoryList}
            loading={loading}
            typeForPagination="setRegulatoryListPageIndex"
            />
        </div>
      </div>
    </React.Fragment>
  );
};

export default RegulatoryList;
