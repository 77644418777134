// React
import React from "react";
// Third party
import moment from "moment/moment";
// Utils
import { getFileSize } from "../../../../utils";

interface FileItem {
  fileId: string;
  filename: string;
  size: number;
  dateModified: string;
}

interface FundamentalData {
  files?: FileItem[];
  [key: string]: any;
}

interface AttachedFilesProps {
  data: FundamentalData;
  handleFileDownload: (fileId: string, filename: string) => void;
}

const AttachedFiles: React.FC<AttachedFilesProps> = ({
  data,
  handleFileDownload,
}) => {
  const files = data?.files || [];
  return (
    <div className="overflow-x-auto bg-white">
      <div className="min-w-full inline-block align-middle">
        <div className="table-container">
          <table className="min-w-full dark:bg-table-dark  rounded-md">
            <caption className="text-left heading-bold text-title dark:bg-black  pb-2 pt-4 px-4">
              Attached Files
            </caption>
            <thead className="= dark:bg-black">
              <tr></tr>
            </thead>
            <tbody className="" data-testid="attached-files">
              {files?.length > 0 ? (
                data?.files?.map((item: FileItem, index: number) => (
                  <tr key={`files-${index}`}>
                    <td className="px-3.5 py-2  text-[#000817] dark:text-white   text-base font-medium text-left">
                      <button
                    
                        onClick={() =>
                          handleFileDownload(item.fileId, item.filename)
                        }
                      >
                        {item.filename}
                      </button>
                    </td>
                    <td className="px-3.5 py-2  text-[#000817] dark:text-white   text-base  text-left">
                      {getFileSize(item.size)}
                    </td>
                    <td className="px-3.5 py-2  text-[#B2B2B2] dark:text-white   text-base  text-left">
                      {moment(item?.dateModified).format("MM/DD/YYYY hh:mm A")}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td
                    colSpan={3}
                    className="text-primary font-bold text-base p-4"
                  >
                    None
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AttachedFiles;
