import React, { forwardRef, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { OptionItem } from "../../interfaces/pages/variedInterfaces";
import { handleFormError } from "../../utils/errorHandling";

// Helper function to format date to ISO string
const formatDateToISOString = (date: Date, time: any): string => {
  if (time) {
    return date.toISOString();
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

interface InputFieldProps {
  label?: any;
  id: any;
  name: any;
  type: "text" | "date" | "textarea" | "select" | "email";
  placeholder: any;
  serverError?: any;
  formik?: any;
  showIcon?: any;
  rows?: any;
  required?: any;
  last?: any;
  optionList?: any;
  labelKey?: any;
  valueKey?: any;
  bgColor?: any;
  time?: any;
  onChange?: ((value: any) => void) | undefined;
  isDynamic?: any;
  value?: any;
  showAdditionalComment?: any;
  onBlur?: any;
  className?: any;
  maxlength?: any;
  onKeyDownCapture?: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  autoSubmit?: boolean;
  cancel?: any;
  setSelectedValue?:any
}

const InputFieldMna = forwardRef<any, InputFieldProps>(
  ({
    id,
    name,
    type,
    placeholder,
    serverError,
    formik,
    showIcon,
    rows,
    required,
    last,
    optionList,
    labelKey,
    valueKey,
    bgColor,
    time,
    isDynamic,
    value,
    onChange,
    maxlength,
    onKeyDownCapture,
    autoSubmit,
    cancel,
    setSelectedValue
  }, ref) => {
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      let newValue = event.target.value;
      setSelectedValue(event.target.value)
      if (typeof newValue === "boolean") {
        newValue = String(newValue);
      }
      console.log(name)
      formik.setFieldValue(name, newValue);
      if (autoSubmit) {
        formik.handleSubmit();
      }
      if (onChange) {
        onChange(newValue);
      }
    };

    let formattedDate: string = "";
    let selectedFormattedDate: Date | null = null;

    if (type === "date") {
      const dateString = formik?.values[id] || value || "";
      if (dateString) {
        const date = new Date(dateString);

        if (time) {
          formattedDate = date.toISOString();
        } else {
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const day = date.getDate().toString().padStart(2, "0");
          formattedDate = `${month}/${day}/${year}`;
        }

        selectedFormattedDate = new Date(formattedDate);
      }
    }

    return (
      <React.Fragment>
        {type === "text" ? (
          <div className="relative" onKeyDownCapture={onKeyDownCapture}>
            <input
              id={id}
              name={name}
              type="text"
              maxLength={maxlength}
              placeholder={placeholder}
              value={isDynamic ? value || "" : formik?.values ? formik?.values[name] : ""}
              onChange={onChange ? onChange : formik && formik.handleChange}
              className={`py-[18px] px-[30px] border rounded-full text-base text-[#7B7B7B] font-light w-full outline-none ${serverError && serverError[id] ? "border-danger :border-danger" : "border-[#C3C1DF] dark:border-white"} bg-[${bgColor ? bgColor : "#FAFAFE"}] dark:bg-primary-dark dark:text-white ${showIcon && "pr-[50px]"}`}
              onBlur={() => {
                setTimeout(() => { }, 0);
              }}
            />
            {showIcon && <span className="absolute right-7 top-5"></span>}
          </div>
        ) : type === "date" ? (
          <ReactDatePicker
            autoFocus
            id={id}
            name={name}
            selected={selectedFormattedDate}
            placeholderText={placeholder}
            className={`dark:text-white dark:placeholder:text-white dark:bg-primary-dark border rounded-md a text-center text-base text-[#7B7B7B] max-w-[100px] font-light w-full bg-[${bgColor ? bgColor : "#FAFAFE"
              }] outline-none ${serverError && serverError[id]
                ? "border-danger dark:border-danger"
                : "border-[#C3C1DF] dark:border-white"
              }`}
            onChange={(date: Date) => {
              try {
                const formattedDate = date
                  ? formatDateToISOString(date, time)
                  : "";
                formik?.setFieldValue(id, formattedDate);
                if (onChange) onChange(formattedDate);
                formik.handleSubmit();
              } catch (error) {
                handleFormError(error);

              }
            }}
            onBlur={cancel}
            dateFormat={time ? "yyyy/MM/dd h:mm aa" : "MM/dd/yyyy"}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            showTimeSelect={time}
            timeFormat="HH:mm"
            timeIntervals={5}
            timeCaption="time"
          />
        ) : type === "textarea" ? (
          <div className="relative">
            <textarea
              id={id}
              name={name}
              value={
                isDynamic
                  ? value || ""
                  : formik?.values
                    ? formik?.values[name]
                    : ""
              }
              rows={rows}
              placeholder={placeholder}
              onChange={formik?.handleChange}
              onBlur={cancel}
              className={`py-[18px] px-[30px] border dark:text-white dark:placeholder:text-white dark:bg-primary-dark rounded-lg text-base text-[#7B7B7B] font-light w-full bg-[${bgColor ? bgColor : "#FAFAFE"
                }] outline-none ${serverError && serverError[id]
                  ? "border-danger dark:border-danger"
                  : "border-[#C3C1DF] dark:border-white"
                } ${showIcon && "pr-[50px]"}`}
            />
            {showIcon && <span className="absolute right-7 top-5"></span>}
          </div>
        ) : type === "select" ? (
          <div className="relative">
            <select
              id={id}
              name={name}
              value={
                isDynamic
                  ? value || ""
                  : formik?.values
                    ? formik?.values[name]
                    : ""
              }
              onChange={handleChange}
              onBlur={cancel}
              autoFocus={true}
              className={`appearance-none px-[10px] border border-primary-default cursor-text rounded-md text-base text-[#7B7B7B] h-full font-light w-full bg-[${bgColor ? bgColor : "#FAFAFE"
                }] outline-none ${serverError && serverError[id]
                  ? "border-danger dark:border-danger"
                  : "border-[#C3C1DF] dark:border-white"
                } dark:text-white dark:placeholder:text-white dark:bg-primary-dark `}
            >
              <option value="">{placeholder}</option>
              {optionList.map((item: OptionItem, index: number) => (
                <option key={index} value={valueKey && item[valueKey]}>
                  {labelKey && item[labelKey]}
                </option>
              ))}
            </select>
            <span className="absolute top-1/2 right-[5px] -translate-y-1/2"></span>
          </div>
        ) : type === "email" ? (
          <div className="relative">
            <input
              id={id}
              name={name}
              type="email"
              placeholder={placeholder}
              value={
                isDynamic
                  ? value || ""
                  : formik?.values
                    ? formik?.values[name]
                    : ""
              }
              onChange={onChange ? onChange : formik && formik.handleChange}
              onBlur={() => {
                setTimeout(() => {
                }, 0);
              }}
              className={`py-[18px] px-[30px] border rounded-full text-base text-[#7B7B7B] font-light w-full outline-none ${serverError && serverError[id]
                ? "border-danger dark:border-danger"
                : "border-[#C3C1DF] dark:border-white"
                } bg-[${bgColor ? bgColor : "#FAFAFE"
                }] dark:bg-primary-dark dark:text-white ${showIcon && "pr-[50px]"
                }`}
            />
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
);

export default InputFieldMna;
