// React
import { useEffect, useState } from "react";
// Third party
import { useParams } from "react-router-dom";
// OpenAPI
import { expiredTokenValidation } from "../../api/expiredTokenValidation";
// Types
import { HandleMenuProps } from "../../interfaces/pages/variedInterfaces";
// Components
import ErrorKeyValuePairList from "./ErrorDetailsComponents/ErrorKeyValuePairList";
// Services
import { fetchErrorLogById } from "./services/ErrorLogService";

export default function ErrorDetails({
  isMenuOpen,
  setIsMenuOpen,
}: HandleMenuProps) {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  const fetchErrorData = async () => {
    if (!id) return;
    setLoading(true);
    try {
      const response = await fetchErrorLogById(id);
      setData(response.data);
    } catch (error) {
      expiredTokenValidation(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchErrorData();
  }, [id]);

  return (
    <div className={` ${isMenuOpen ? "pl-[284px]" : "pl-[60px]"} duration-500`}>
      <div className="w-full bg-gray-200 dark:bg-gray-900">
        <div className="container-fluid mx-auto flex items-start justify-center">
          <div className="w-full p-6">
            <ul className="divide-y divide-[#DDDBEE] rounded-md border border-[#DDDBEE]">
              {data && <ErrorKeyValuePairList data={data} />}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
