// React
import React from "react";
// Components
import Table from "../../../components/Tables/RecentEvent";
import { Columns } from "../../../components/Columns/Columns";
// Types
import { Sort } from "../../../interfaces/pages/variedInterfaces";

interface RegulatoryNotesProps {
  fetchRegulatoryNoteList: (
    pageIndex: number,
    pageSize: number,
    sortBy: Sort[]
  ) => Promise<any>;
  openModal: (content: string) => void;
  columnsInstance: Columns;
}

const RegulatoryNotes: React.FC<RegulatoryNotesProps> = ({
  fetchRegulatoryNoteList,
  openModal,
  columnsInstance,
}) => {
  return (
    <div className="border border-[#DCDBEE] rounded-md overflow-hidden" data-testid="notes-section">
      <div className="min-w-full rounded-bl-lg rounded-br-lg bg-table-light">
        <div className="text-left font-semibold dark:bg-black text-title pt-4 px-4">
          <div className="flex items-center justify-between">
            <div className="text-xs xs:text-xs sm:text-sm md:text-base lg:text-lg">
              Notes
            </div>
          </div>
        </div>
        <div className="pt-[24px] pb-4 px-4">
          <Table
            fetchData={fetchRegulatoryNoteList}
            columns={columnsInstance.NotesListDetail()}
            additionalDataKey="notes"
            openModal={openModal}
          />
        </div>
      </div>
    </div>
  );
};

export default RegulatoryNotes;
