import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/userSlice";
import paginationReducer from "../features/paginationSlice";
import mnaDetailsReducer from "../features/mnaDetailsSlice";
import errorsListPaginationReducer from "../features/errorListPagination";
import fundamentalListPaginationReducer from "../features/fundamentalListPagination";
import mnaListPaginationReducer from "../features/mnaListPagination";
import notesForMnaPaginationReducer from "../features/notesForMnaPagination";
import notesListPaginationReducer from "../features/notesListPagination";
import regulatoryListPaginationReducer from "../features/regulatoryListPagination";
import filesListPaginationReducer from "../features/filesListPagination";
import notesForFundamentalReducer from "../features/notesForFundamentalPagination";

export const store = configureStore({
  reducer: {
    user: userReducer,
    pagination: paginationReducer,
    mnaDetails: mnaDetailsReducer,
    errorsListPagination: errorsListPaginationReducer,
    fundamentalListPagination: fundamentalListPaginationReducer,
    mnaListPagination: mnaListPaginationReducer,
    notesForMnaPagination: notesForMnaPaginationReducer,
    notesListPagination: notesListPaginationReducer,
    regulatoryListPagination: regulatoryListPaginationReducer,
    filesListPagination: filesListPaginationReducer,
    notesForFundamental: notesForFundamentalReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
