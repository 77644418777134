// src/redux/slices/paginationSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PaginationState {
  pageIndex: number;
}

const initialState: PaginationState = {
  pageIndex: 0,
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setPageIndex(state, action: PayloadAction<number>) {
      state.pageIndex = action.payload;
    },
    resetPage(state) {
      state.pageIndex = 0;
    },
  },
});

export const { setPageIndex: setPageIndexAction, resetPage: resetPageAction } =
  paginationSlice.actions;

export default paginationSlice.reducer;
