// React
import React from "react";
// Types
import { ItemMetaData } from "../../../interfaces/pages/variedInterfaces";

export default function MnaLinks({ data }: { data: any }) {
  return (
    <div className="table-container pb-4 bg-table-light dark:bg-table-dark">
      <table className="w-full rounded-bl-lg rounded-br-lg  bg-table-light dark:bg-table-dark">
        <caption className="text-left heading-bold pt-4 pb-2 px-4">
          Links
        </caption>
        <thead>
          <tr>
            <th scope="col" className="w-1/3"></th>
            <th scope="col" className="w-2/3"></th>
          </tr>
        </thead>
        <tbody className="">
          {data?.links?.map((item: ItemMetaData, index: number) => (
            <tr key={`links-${index}`}>
              <td className="px-4 pt-2 text-[#696868] dark:text-white text-sm font-medium text-left">
                {item?.category}
              </td>
              <td className="px-4 pt-2 text-[#000817] dark:text-white text-sm font-medium truncate max-w-[calc(100vw-72rem)]">
                <a target="_blank" rel="noopener noreferrer" href={item.link}>
                  {item?.link}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
