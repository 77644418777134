import { FileDomainApi } from "../../../openapi";
import { apiConfig } from "../../../components/ConfigurationApi/Configuration";
import { expiredTokenValidation } from "../../../api/expiredTokenValidation";
import { getSortArray } from "../../../helpers/getSortArray";
import { ResponseFile, Sort } from "../../../interfaces/pages/variedInterfaces";
import { downloadFileApi } from "../../NoteDetails/services/noteServices";

const api = new FileDomainApi(apiConfig());

export const fetchFiles = async (
  search: string,
  pageIndex: number,
  pageSize: number,
  sortBy: Sort[]
) => {
  try {
    return search
      ? await api.findFiles(
          `filename:${search}`,
          undefined,
          undefined,
          undefined
        )
      : await api.findFiles("", pageIndex, pageSize, getSortArray(sortBy));
  } catch (error) {
    expiredTokenValidation(error);
    throw error;
  }
};

export const downloadFilesApi = async (
  id: string,
  fileName: string,
  setLoading?: (isLoading: boolean) => void
) => {
  setLoading && setLoading(true);
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    const response = await downloadFileApi(id);
    const fileUrl = (response as ResponseFile).config.url;

    const fileResponse = await fetch(fileUrl, { headers });

    if (!fileResponse.ok) {
      console.error("Failed to fetch the file.");
      setLoading && setLoading(false);
      return;
    }

    const fileData = await fileResponse.blob();

    const match = fileName.match(/([^/\\]+)$/); 
    const validatedFileName = match ? match[0] : "";

    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(fileData);
    downloadLink.download = validatedFileName;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setLoading && setLoading(false);
  } catch (error) {
    console.error("Error downloading file:", error);
    setLoading && setLoading(false);
  }
};
