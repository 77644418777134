import { showAlert } from "../components/Modal/AlertModal";
import { ErrorFileData } from "../interfaces/pages/Errors";

export function handleFormError(
    error: any
  ) {
    if (error.response) {
      if (
        error.response.data?.exception &&
        !error.response.data?.fieldErrors?.length
      ) {
        showAlert({
          message: error.response.data?.message || "Something went wrong!",
          type: "error",
        });
      }
      if (error.response.data?.fieldErrors?.length) {
        showAlert({
          message: "Please check the correctness of the fields",
          type: "error",
        });
        const errorsObject = {} as any;
        error.response.data?.fieldErrors.forEach((error: ErrorFileData) => {
          errorsObject[error.field] = error.defaultMsg;
        });
      }
    }
  }
  