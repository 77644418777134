// React
import React, { useEffect, useState } from "react";
// Third party
import moment from "moment";
// Components
import EditableFieldMna from "../../../components/EditableFields/EditableField";
import { addBrokerClosingDateLocally } from "../../../components/EditableFields/EditableFieldsUtilities/editableFieldsUtils";
// Types
import { Item } from "../../../interfaces/pages/variedInterfaces";
// Icons
import DeleteIcon from "../../../assets/svg/delete.svg";
import DeleteModalMNADates from "../../../components/Modal/DeleteModalMNADates";
import { RootState } from "../../../store/store";
import { useAppDispatch, useAppSelector } from "../../../store/storeHooks";
import { fetchMnaDetails } from "../../../features/mnaDetailsSlice";
import { fetchFeatures } from "../services/mnaService";
import { handleFormError } from "../../../utils/errorHandling";

interface DatesProp {
  data: any;
  setFetchRequired: React.Dispatch<React.SetStateAction<boolean>>;
  setData: React.Dispatch<React.SetStateAction<any>>;
  fetchMnaData: () => void;
  safeId: any;
  setDateData: any;
  dateData: any;
}
export default function Dates({
  data,
  setFetchRequired,
  setData,
  fetchMnaData,
  safeId,
}: DatesProp) {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [dateToDelete, setDateToDelete] = useState<string | undefined>("");

  const dispatch = useAppDispatch();
  const mnaDetails = useAppSelector(
    (state: RootState) => state.mnaDetails.mnaDetails
  );



  const handleFetchMnaData = async () => {
    fetchMnaData();
  };

  const openDeleteModal = (deleteId: string | undefined) => {
    setDateToDelete(deleteId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDateToDelete("");
    setIsDeleteModalOpen(false);
  };

  const handleDeleteDate = async () => {
    if (dateToDelete === null) return;
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${API_URL}/api/domain/mna/dates/${dateToDelete}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete Date");
      }
      setFetchRequired(true);
    } catch (error) {
      handleFormError(error);
    } finally {
      closeDeleteModal();
    }
  };

  return (
    <>
      <div className="border border-[#DCDBEE] rounded-md overflow-visible p-4">
        <table className="w-full rounded-bl-lg rounded-br-lg bg-table-light dark:bg-table-dark">
          <caption className="text-left heading-bold text-title pb-2 bg-table-light dark:bg-table-dark">
            Dates
          </caption>
          <tbody className="">
            <tr className="w-full">
              <td className="align-top w-1/2">
                <p className="text-[#696868] dark:text-white text-sm font-normal text-left mt-2">
                  Out Dates:
                </p>
              </td>
              <td className="flex w-full">
                <div
                  className={`flex ${
                    data.outDates?.length > 1 ? "flex-col" : "flex-row"
                  } w-full`}
                >
                  {data.outDates?.map((item: Item, index: number) => (
                    <div
                      key={`out-dates${index}`}
                      className="flex w-full justify-between"
                    >
                      <div className="">
                        <EditableFieldMna
                          data={
                            item?.dateEvent
                              ? moment(item.dateEvent).isValid()
                                ? moment(item.dateEvent).format("MM/DD/YYYY")
                                : ""
                              : ""
                          }
                          fieldtype="date"
                          fieldName="outDates"
                          dateIndex={index}
                          fullData={data}
                          dateItemValue={item.dateEvent}
                          setFetchMnaDetails={setFetchRequired}
                          setLocalData={setData}
                          fetchMnaData={handleFetchMnaData}
                        />
                      </div>
                      <div className="">
                        <EditableFieldMna
                          testId={`comment-OutDates-${index}`}
                          fieldtype="text"
                          fieldName="outDates"
                          data={item?.comment}
                          fullData={data}
                          dateIndex={index}
                          setFetchMnaDetails={setFetchRequired}
                          setLocalData={setData}
                          fetchMnaData={handleFetchMnaData}
                        />
                      </div>
                      <div
                        className="mt-2 cursor-pointer"
                        data-testid="delete-date"
                      >
                        <img
                          src={DeleteIcon}
                          alt="delete"
                          onClick={() => {
                            console.log(index);
                            openDeleteModal(mnaDetails.outDates[index].id);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
            <tr className="w-full">
              <td></td>

              <td className="flex justify-end w-full">
                {" "}
                <button
                  type="button"
                  data-testid="AddNewPlus"
                  onClick={() => {
                    addBrokerClosingDateLocally("outDates", safeId, setData);
                  }}
                  className={`text-secondary text-sm py-1 px-4 font-bold rounded-md border border-[#C3C1DF] w-auto bg-[#FAFAFE] dark:bg-button-dark dark:text-white ${
                    data.outDates?.length === 0 ? "" : "my-2"
                  }`}
                >
                  Add new
                </button>
              </td>
            </tr>
            <tr className="w-full">
              <td className="align-top w-1/2">
                <p className="text-[#696868] dark:text-white text-sm font-normal text-left mt-2">
                  Broker Closing Estimate Date:
                </p>
              </td>
              <td className="flex w-full">
                <div
                  className={`flex ${
                    data.brokerClosingDates?.length > 1
                      ? "flex-col"
                      : "flex-row"
                  } w-full`}
                >
                  {data.brokerClosingDates?.map((item: Item, index: number) => (
                    <div
                      key={`broker-closing${index}`}
                      className="flex w-full justify-between"
                    >
                      <div className="">
                        <EditableFieldMna
                          data={
                            item?.dateEvent
                              ? moment(item.dateEvent).isValid()
                                ? moment(item.dateEvent).format("MM/DD/YYYY")
                                : ""
                              : ""
                          }
                          fieldtype="date"
                          fieldName="brokerClosingDates"
                          dateIndex={index}
                          fullData={data}
                          dateItemValue={item.dateEvent}
                          setFetchMnaDetails={setFetchRequired}
                          setLocalData={setData}
                          fetchMnaData={handleFetchMnaData}
                        />
                      </div>
                      <div className="">
                        <EditableFieldMna
                          fieldtype="text"
                          testId={`comment-brokerClosingDates-${index}`}
                          fieldName="brokerClosingDates"
                          data={item?.comment}
                          fullData={data}
                          dateIndex={index}
                          setFetchMnaDetails={setFetchRequired}
                          setLocalData={setData}
                          fetchMnaData={handleFetchMnaData}
                        />
                      </div>
                      <div
                        className="mt-2 cursor-pointer"
                        data-testid="delete-date"
                      >
                        <img
                          src={DeleteIcon}
                          alt="delete"
                          onClick={() => {
                            openDeleteModal(
                              mnaDetails.brokerClosingDates[index].id
                            );
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
            <tr className="w-full">
              <td></td>
              <td className="flex justify-end w-full">
                <button
                  type="button"
                  data-testid="AddNewPlus"
                  onClick={() => {
                    addBrokerClosingDateLocally(
                      "brokerClosingDates",
                      safeId,
                      setData
                    );
                  }}
                  className={`text-secondary text-sm py-1 px-4 font-bold rounded-md border border-[#C3C1DF] w-auto bg-[#FAFAFE] dark:bg-button-dark dark:text-white ${
                    data.brokerClosingDates?.length === 0 ? "" : "my-2"
                  }`}
                >
                  Add new
                </button>
              </td>
            </tr>
            <tr className="w-full">
              <td className="align-top w-1/2">
                {" "}
                <p className="text-[#696868] dark:text-white text-sm font-normal text-left mt-2">
                  Financing Date:
                </p>
              </td>
              <td className="flex w-full">
                <div
                  className={`flex ${
                    data.financingDates?.length > 1 ? "flex-col" : "flex-row"
                  } w-full`}
                >
                  {data.financingDates?.map((item: Item, index: number) => (
                    <div
                      key={`financingDatess${index}`}
                      className="flex w-full justify-between"
                    >
                      <div className="">
                        <EditableFieldMna
                          data={
                            item?.dateEvent
                              ? moment(item.dateEvent).isValid()
                                ? moment(item.dateEvent).format("MM/DD/YYYY")
                                : ""
                              : ""
                          }
                          fieldtype="date"
                          fieldName="financingDates"
                          dateIndex={index}
                          fullData={data}
                          dateItemValue={item.dateEvent}
                          setFetchMnaDetails={setFetchRequired}
                          setLocalData={setData}
                          fetchMnaData={handleFetchMnaData}
                        />
                      </div>
                      <div className="">
                        <EditableFieldMna
                          fieldtype="text"
                          testId={`comment-financingDates-${index}`}
                          fieldName="financingDates"
                          data={item?.comment}
                          fullData={data}
                          dateIndex={index}
                          setFetchMnaDetails={setFetchRequired}
                          setLocalData={setData}
                          fetchMnaData={handleFetchMnaData}
                        />
                      </div>
                      <div
                        className="mt-2 cursor-pointer"
                        data-testid="delete-date"
                      >
                        <img
                          src={DeleteIcon}
                          alt="delete"
                          onClick={() => {
                            openDeleteModal(
                              mnaDetails.financingDates[index].id
                            );
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
            <tr className="w-full">
              <td></td>
              <td className="flex justify-end w-full">
                {" "}
                <button
                  type="button"
                  data-testid="AddNewPlus"
                  onClick={() => {
                    addBrokerClosingDateLocally(
                      "financingDates",
                      safeId,
                      setData
                    );
                  }}
                  className={`text-secondary text-sm py-1 px-4 font-bold rounded-md border border-[#C3C1DF] w-auto bg-[#FAFAFE] dark:bg-button-dark dark:text-white ${
                    data.financingDates?.length === 0 ? "" : "my-2"
                  }`}
                >
                  Add new
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {isDeleteModalOpen && (
        <DeleteModalMNADates
          title="Are you sure you want to delete this date?"
          onClose={closeDeleteModal}
          onConfirm={handleDeleteDate}
        />
      )}
    </>
  );
}
